import React, { useEffect, useState } from 'react';
import BuyCreditsModal from '../../../modal-buy-credits/modal-buy-credits';
import LocationNotAllowedModal from '../location-not-allowed.modal';
import NoLocationAvailableModal from '../no-location-available.modal';
import './styles.scss';
import DepositModal from '../../../modal-make-deposit/modal-make-deposit';
import { GameProduct } from '../../../../../services/get-game-products/get-game-products';
import { QuickCreditsModal } from './quick-credits-modal';
import { useProductsAndBalances } from '../../../../../hooks/use-products-and-balances/use-products-and-balances';

export type GameContainerCreditsModalManagerProps = {
  hide: () => void; // trigger modal close.
  isLowCreditMode: boolean;
  stage: 'initial' | 'slow' | 'fast' | 'immediate';
  spinsRemaining?: number; // quick modal
  initState?: ModalDisplayState;
};

export type ModalDisplayState =
  | 'deposit'
  | 'credits'
  | 'quick-credits'
  | 'locationNotAvailable'
  | 'locationNotAllowed'
  | 'loading';

export const QuickCreditsModalManager = ({
  hide,
  isLowCreditMode,
  stage,
  spinsRemaining,
  initState,
}: GameContainerCreditsModalManagerProps) => {
  const [displayState, setDisplayState] = useState<ModalDisplayState>(
    initState || 'quick-credits',
  );

  const { gameProducts, balances } = useProductsAndBalances();
  const [instantPackage, setInstantPackage] = useState(null);

  useEffect(() => {
    if (!balances || balances.credits === undefined) {
      setDisplayState('credits'); // Ensures modal opens even if no previous credits
      return;
    }

    if ((balances.credits_purchased_count ?? 0) > 0) {
      setDisplayState('quick-credits');
      return;
    }

    if (
      gameProducts?.length > 0 &&
      (balances.credits_purchased_count ?? 0) < 1
    ) {
      setDisplayState('credits');
    }
  }, [balances, gameProducts]);

  return (
    <>
      {displayState === 'quick-credits' &&
        (balances?.credits_purchased_count ?? 0) > 0 && (
          <QuickCreditsModal
            isLowCreditMode={isLowCreditMode}
            setDisplayState={setDisplayState}
            hide={hide}
            balances={balances}
            gameProducts={gameProducts}
            stage={stage}
            spinsRemaining={spinsRemaining}
            showWagerDetails={(gameProduct: GameProduct) => {
              setInstantPackage(gameProduct);
              setDisplayState('credits');
            }}
          />
        )}
      {displayState === 'credits' && (
        <BuyCreditsModal
          show
          onClose={() => hide()}
          instantPackage={instantPackage}
          onOpenQuickCredits={() => {
            setDisplayState('quick-credits');
          }}
        />
      )}
      {displayState === 'deposit' && (
        <DepositModal show onClose={() => hide()} />
      )}
      {displayState === 'locationNotAvailable' && (
        <NoLocationAvailableModal show onClose={hide} />
      )}
      {displayState === 'locationNotAllowed' && (
        <LocationNotAllowedModal show onClose={hide} />
      )}
    </>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { bp } from 'common/theme';
import { Facebook } from 'icons/svg/facebook';
import { Twitter } from 'icons/svg/twitter';
import { Instagram } from 'icons/svg/instagram';
import { css } from '@emotion/react';

type SocialButtonProps = {
  disableClick?: boolean;
};

const SocialButton = styled.a<SocialButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
  border-radius: 100%;
  ${({ disableClick }) =>
    disableClick &&
    css`
      cursor: default;
      pointer-events: none;
    `}
`;

const SocialBlock = styled.div`
  display: flex;
  justify-content: center;

  @media ${bp.lg} {
    justify-content: space-between;
  }
`;

export const socialLinks = {
  facebook: {
    icon: Facebook,
    url: 'https://www.facebook.com/bspot.horseplay',
    iconDataTestId: 'facebook-icon',
  },
  twitter: {
    icon: Twitter,
    url: 'https://x.com/HorseplayGames_',
    iconDataTestId: 'twitter-icon',
  },
  instagram: {
    icon: Instagram,
    url: 'https://www.instagram.com/horseplay_games/',
    iconDataTestId: 'instagram-icon',
  },
};

type Socials = {
  color?: string;
  disableClick?: boolean;
};

export function Socials({
  color = 'var(--secondary-color)',
  disableClick,
}: Socials) {
  const buttons = Object.entries(socialLinks).map(([social, config]) => {
    return (
      <SocialButton
        disableClick={disableClick}
        key={social}
        id={social}
        href={config.url}
      >
        <config.icon fill={color} />
      </SocialButton>
    );
  });

  return <SocialBlock>{buttons}</SocialBlock>;
}

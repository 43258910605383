import React from 'react';
import { SvgProps } from './svg';

export function XSocial({
  size,
  width = '1rem',
  height = '1rem',
  fill = '#282828',
  onClick,
}: SvgProps) {
  return (
    <a
      aria-label='X link'
      href={'https://twitter.com/HorseplayGames_'}
      style={{
        display: 'flex',
        padding: '12px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: '43px',
        border: '1px solid #4D4D4D',
      }}
    >
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_848_11389)'>
          <path
            d='M9.52373 6.77569L15.4811 0H14.0699L8.89493 5.88203L4.7648 0H0L6.24693 8.89552L0 16H1.4112L6.87253 9.78704L11.2352 16H16M1.92053 1.04127H4.08853L14.0688 15.0099H11.9003'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_848_11389'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}

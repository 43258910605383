import { Suspense, useEffect, useState } from 'react';
import { AppWrapper } from './AppWrapper';
import { Routes as AppRoutes } from '../common/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout, DefaultLayout, AccountLayout } from '../components';
import { NotFound } from '../pages/not-found';
import { lazyWithRetry } from '../common/utils';
import { DynamicContent } from '../pages/content/dynamic-content';
import {
  FeatureFlags,
  FeatureFlagsService,
} from '../services/feature-flags/feature-flags';
import { AppLoader } from '../components/molecules/app-loader/app-loader';
import { injectGTM } from '../common/gtm/gtm';
import { sendGTMEvent } from '../services/_gtm-analytics/gtm-analytics';
import { useAuth } from '../contexts/authentication';
import { DevBanner } from '../components/atoms/dev-banner/dev-banner';
import { HomePage } from '../pages/home-page';
import { LobbyPage } from '../pages/lobby-page';
import { useSession } from 'next-auth/react';
import { GameSdkProvider } from '../contexts/game-sdk/game-sdk';
import Game from 'pages/game/game';
import WagerHistory from 'pages/account-history/wager_history';
import PlayerInfo from 'pages/player-info/player-info';
import Login from 'pages/login/login';
import { injectAppsFlyer } from 'services/_af-events/appsflyer';
import { TournamentsContextProvider } from 'contexts/tournaments/tournaments-context';
import MakeDepositSuccess from 'pages/make-deposit-success/make-deposit-success';
import MakeDepositError from 'pages/make-deposit-error/make-deposit-error';
import MakeDepositPending from 'pages/make-deposit-pending/make-deposit-pending';
import MakeDepositPage from 'pages/make-deposit/make-deposit';
import WithdrawPage from 'pages/withdraw/withdrawPage';
import WagersPage from 'pages/wagers/wagers';
import CommunicationPreferencePage from 'pages/communication-preferences/communication-preferences';
import PrivacyPolicy from 'pages/privacy-policy/privacy-policy';
import About from 'pages/about/about';
import ResponsibleGaming from 'pages/responsible-gaming/responsible-gaming';
import TermsOfUse from 'pages/terms-of-use/terms-of-use';
import { Affiliates } from 'pages/affiliates';
import { setPersistentCookie } from 'common/utils';

const LazyRegister = lazyWithRetry(
  () => import('pages/create-account/create-account'),
);
const LazyForgotPassword = lazyWithRetry(
  () => import('pages/password-forgot/password-forgot'),
);
const LazyResetPassword = lazyWithRetry(
  () => import('pages/password-reset/password-reset'),
);

const LazyDocumentsCenter = lazyWithRetry(
  () => import('pages/document-center/document-center'),
);

const LazyGamesByCategory = lazyWithRetry(
  () => import('pages/game-by-category/game-by-category'),
);

const LazyPromotions = lazyWithRetry(
  () => import('pages/promotions-wrapper/promotions-wrapper'),
);

const LazyTournaments = lazyWithRetry(
  () => import('pages/tournaments/tournaments'),
);

const LazyTermsOfUse = lazyWithRetry(
  () => import('pages/terms-of-use/terms-of-use'),
);
const LazyError = lazyWithRetry(() => import('pages/error/error'));

const LazyChangePassword = lazyWithRetry(
  () => import('pages/password-change/password-change'),
);
const LazyPlayerInfo = lazyWithRetry(
  () => import('pages/player-info/player-info'),
);
// -- these two should live inside an outlet for account-history
const LazyBalances = lazyWithRetry(
  () => import('pages/account-history/balances'),
);
const LazyAccountHistory = lazyWithRetry(
  () => import('pages/account-history/wager_history'),
);
// --
const LazyResponsibleGamingSettings = lazyWithRetry(
  () => import('pages/responsible-gaming-settings/responsible-gaming-settings'),
);
const LazyCommunicationPreferences = lazyWithRetry(
  () => import('pages/communication-preferences/communication-preferences'),
);

export const App = () => {
  const { auth } = useAuth();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await FeatureFlagsService.getFeatureFlags();
        setFeatureFlags(data);
        injectGTM();
        injectAppsFlyer();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // Track device orientation.
  useEffect(() => {
    const updateOrientationInDataLayer = () => {
      const orientation = window.matchMedia('(orientation: landscape)').matches
        ? 'landscape'
        : 'portrait';
      sendGTMEvent({
        event: 'device-orientation-change',
        orientation,
        user_id: auth?.session?.playerId,
      });
    };
    updateOrientationInDataLayer();
    const mediaQueryList = window.matchMedia('(orientation: landscape)');
    const orientationChangeHandler = () => updateOrientationInDataLayer();
    mediaQueryList.addEventListener('change', orientationChangeHandler);
    return () => {
      mediaQueryList.removeEventListener('change', orientationChangeHandler);
    };
  }, [auth?.session?.playerId]);

  const enableNextAuth = ENABLE_NEXT_AUTH === 'true';

  return (
    <>
      {featureFlags ? (
        <AppWrapper isNextAuthEnabled={enableNextAuth}>
          <Suspense fallback={<></>}>
            <Routes>
              <Route element={<Layout />}>
                <Route
                  path={AppRoutes.Home.path}
                  element={
                    <RouteGuard route={AppRoutes.Home.path}>
                      <RedirectToExternalUrl url={`${AppRoutes.Home.path}`} />
                    </RouteGuard>
                  }
                />
                //todo
                <Route
                  path={AppRoutes.Lobby.path}
                  element={
                    <RouteGuard route={AppRoutes.Lobby.path}>
                      <RedirectToExternalUrl url={`${AppRoutes.Lobby.path}`} />
                    </RouteGuard>
                  }
                />
                <Route
                  path='/en/casino'
                  element={<Navigate to={AppRoutes.Lobby.path} />}
                />
                <Route
                  path={AppRoutes.Login.path}
                  element={
                    enableNextAuth ? (
                      <RedirectToExternalUrl url={`${AppRoutes.Login.path}`} />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route
                  path={'en/logout'}
                  element={<RedirectToExternalUrl url={`/en/logout`} />}
                />
                <Route
                  path={'en/logout/sign-out'}
                  element={
                    <RedirectToExternalUrl url={`/en/logout/sign-out`} />
                  }
                />
                <Route
                  path={AppRoutes.CreateAccount.path}
                  element={<LazyRegister />}
                />
                <Route
                  path={AppRoutes.CreateAccountSuccess.path}
                  element={<Navigate to={AppRoutes.Home.path} />}
                />
                <Route
                  path={AppRoutes.CreateAccountFail.path}
                  element={<Navigate to={AppRoutes.Home.path} />}
                />
                <Route
                  path={AppRoutes.AccountOnBoarding.path}
                  element={
                    <RedirectToExternalUrl
                      url={`${AppRoutes.AccountOnBoarding.path}`}
                    />
                  }
                />
                <Route
                  path={AppRoutes.ForgotPassword.path}
                  element={<LazyForgotPassword />}
                />
                <Route
                  path={AppRoutes.ResetPassword.path}
                  element={<LazyResetPassword />}
                />
                <Route
                  path={AppRoutes.GamesByCategory.path}
                  element={<LazyGamesByCategory />}
                />
                <Route
                  path={AppRoutes.Promotions.path}
                  element={<LazyPromotions />}
                />
                <Route
                  path={AppRoutes.Tournaments.path}
                  element={
                    <RedirectToExternalUrl
                      url={`${AppRoutes.Tournaments.path}`}
                    />
                    // <LazyTournaments />
                  }
                />
                <Route
                  path={AppRoutes.DynamicPage.path}
                  element={<DynamicContent />}
                />
                <Route
                  path={AppRoutes.Affiliates.path}
                  element={
                    // <RedirectToExternalUrl
                    //   url={`${AppRoutes.Affiliates.path}`}
                    // />
                    <Affiliates />
                  }
                />
                <Route
                  path={AppRoutes.TermsOfUse.path}
                  element={
                    // <RedirectToExternalUrl
                    //   url={`${AppRoutes.TermsOfUse.path}`}
                    // />
                    <TermsOfUse />
                  }
                />
                <Route
                  path={AppRoutes.ResponsibleGaming.path}
                  element={
                    // <RedirectToExternalUrl
                    //   url={`${AppRoutes.ResponsibleGaming.path}`}
                    // />
                    <ResponsibleGaming />
                  }
                />
                <Route
                  path={AppRoutes.About.path}
                  element={
                    // <RedirectToExternalUrl
                    //   url={`${AppRoutes.About.path}`}
                    // />
                    <About />
                  }
                />
                <Route
                  path={AppRoutes.PrivacyPolicy.path}
                  element={
                    // <RedirectToExternalUrl
                    //   url={`${AppRoutes.PrivacyPolicy.path}`}
                    // />
                    <PrivacyPolicy />
                  }
                />
                <Route element={<AccountLayout />}>
                  <Route
                    path={AppRoutes.ChangePassword.path}
                    element={
                      <RouteGuard
                        route={AppRoutes.ChangePassword.path}
                        deeplink
                      >
                        <LazyChangePassword />
                      </RouteGuard>
                    }
                  />
                  <Route
                    path={AppRoutes.PlayerInfo.path}
                    element={
                      <RouteGuard route={AppRoutes.PlayerInfo.path} deeplink>
                        <PlayerInfo />
                      </RouteGuard>
                    }
                  />
                  <Route
                    path={AppRoutes.WagerHistory.path}
                    element={
                      <RouteGuard route={AppRoutes.WagerHistory.path} deeplink>
                        <WagerHistory />
                        {/*<RedirectToExternalUrl*/}
                        {/*  url={`${AppRoutes.WagerHistory.path}`}*/}
                        {/*/>*/}
                      </RouteGuard>
                    }
                  />
                  <Route
                    path={AppRoutes.CommunicationPreferences.path}
                    element={
                      <RouteGuard
                        route={AppRoutes.CommunicationPreferences.path}
                        deeplink
                      >
                        {/*<RedirectToExternalUrl*/}
                        {/*  url={`${AppRoutes.CommunicationPreferences.path}`}*/}
                        {/*/>*/}
                        <CommunicationPreferencePage />
                      </RouteGuard>
                    }
                  />
                  <Route
                    path={AppRoutes.ResponsibleGamingSettings.path}
                    element={
                      <RouteGuard
                        route={AppRoutes.ResponsibleGamingSettings.path}
                        deeplink
                      >
                        <LazyResponsibleGamingSettings />
                      </RouteGuard>
                    }
                  />
                  <Route
                    path={AppRoutes.MakeDepositPage.path}
                    element={
                      <RouteGuard
                        route={AppRoutes.MakeDepositPage.path}
                        deeplink
                      >
                        <MakeDepositPage isModal={false} />
                        {/*<RedirectToExternalUrl*/}
                        {/*  url={`${AppRoutes.MakeDepositPage.path}`}*/}
                        {/*/>*/}
                      </RouteGuard>
                    }
                  />

                  <Route
                    path={AppRoutes.WithdrawPage.path}
                    element={
                      <RouteGuard route={AppRoutes.WithdrawPage.path} deeplink>
                        <WithdrawPage />
                      </RouteGuard>
                    }
                  />
                  <Route
                    path={AppRoutes.WagersPage.path}
                    element={
                      <RouteGuard route={AppRoutes.WagersPage.path} deeplink>
                        {/*<RedirectToExternalUrl*/}
                        {/*  url={`${AppRoutes.WagersPage.path}`}*/}
                        {/*/>*/}
                        <WagersPage isModal={false} />
                      </RouteGuard>
                    }
                  />
                  <Route
                    path={AppRoutes.DocumentCenter.path}
                    element={
                      <RouteGuard
                        route={AppRoutes.DocumentCenter.path}
                        deeplink
                      >
                        <LazyDocumentsCenter />
                      </RouteGuard>
                    }
                  />
                </Route>
              </Route>

              <Route
                path={AppRoutes.Game.path}
                element={
                  <RouteGuard route={AppRoutes.Game.path} deeplink>
                    <TournamentsContextProvider>
                      <GameSdkProvider>
                        <Game />
                      </GameSdkProvider>
                    </TournamentsContextProvider>
                  </RouteGuard>
                }
              />

              <Route
                path={AppRoutes.MakeDepositSuccessIframePage.path}
                element={
                  <MakeDepositSuccess />
                  // <RedirectToExternalUrl
                  //   url={`${AppRoutes.MakeDepositSuccessIframePage.path}`}
                  // />
                }
              />
              <Route
                path={AppRoutes.MakeDepositErrorIframePage.path}
                element={
                  <MakeDepositError />
                  // <RedirectToExternalUrl
                  //   url={`${AppRoutes.MakeDepositErrorIframePage.path}`}
                  // />
                }
              />
              <Route
                path={AppRoutes.MakeDepositPendingIframePage.path}
                element={
                  <MakeDepositPending />
                  // <RedirectToExternalUrl
                  //   url={`${AppRoutes.MakeDepositPendingIframePage.path}`}
                  // />
                }
              />
              <Route
                path='/en'
                element={<Navigate replace to={AppRoutes.Lobby.path} />}
              />

              <Route element={<Layout />}>
                <Route path={AppRoutes.Error.path} element={<LazyError />} />
                <Route
                  path={AppRoutes.PageNotFound.path}
                  element={<NotFound />}
                />
                <Route
                  path=''
                  element={<Navigate replace to={AppRoutes.Lobby.path} />}
                />
                <Route
                  path={'*'}
                  element={
                    <Navigate replace to={AppRoutes.PageNotFound.path} />
                  }
                />
              </Route>
            </Routes>
          </Suspense>
        </AppWrapper>
      ) : (
        <div className='bg-black'>
          <AppLoader />
        </div>
      )}
    </>
  );
};

function RouteGuard({
  children,
  route,
  deeplink = false,
}: {
  children: JSX.Element;
  route: string;
  deeplink?: boolean;
}) {
  const enableNextAuth = ENABLE_NEXT_AUTH === 'true';
  let isAuth = false;
  if (enableNextAuth) {
    const session = useSession();
    if (session.status === 'loading') {
      return <AppLoader fullHeight={true} />;
    }
    isAuth = session.status === 'authenticated';
  } else {
    const { auth } = useAuth();
    isAuth = !!auth?.session?.playerId;
  }

  switch (route) {
    case AppRoutes.Game.path:
      if (!isAuth && deeplink) {
        localStorage.setItem(
          'deeplink',
          window.location.pathname + window.location.search,
        );
        setPersistentCookie('deeplink', window.location.pathname + window.location.search, 1);
      }
      return !isAuth ? <Navigate to={AppRoutes.Login.path} /> : children;
    case AppRoutes.ChangePassword.path:
    case AppRoutes.PlayerInfo.path:
    case AppRoutes.WagerHistory.path:
    case AppRoutes.CommunicationPreferences.path:
    case AppRoutes.ResponsibleGamingSettings.path:
    case AppRoutes.MakeDepositPage.path:
    case AppRoutes.MakeDepositSuccessPage.path:
    case AppRoutes.MakeDepositErrorPage.path:
    case AppRoutes.MakeDepositSuccessIframePage.path:
    case AppRoutes.MakeDepositErrorIframePage.path:
    case AppRoutes.WithdrawPage.path:
    case AppRoutes.WagersPage.path:
    case AppRoutes.DocumentCenter.path:
      return !isAuth ? <Navigate to={AppRoutes.Login.path} /> : children;
    case AppRoutes.Home.path:
      return isAuth ? children : <Navigate to={AppRoutes.Lobby.path} />;
    case AppRoutes.Lobby.path:
    default:
      return !isAuth ? children : <Navigate to={AppRoutes.Home.path} />;
  }
}

// look into this.
const RedirectToExternalUrl = ({ url }) => {
  window.location.href = url + window.location.search;
  return null;
};

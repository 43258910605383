import { useEffect, createContext } from 'react';
import { useAuth } from 'contexts/authentication';
import { useSession } from 'next-auth/react';
import { Auth, getUserStatus } from '../../services/auth/login';
import { useNavigate } from 'react-router-dom';

const NextAuthContext = createContext<null>(null);
export const NextAuthContextProvider = ({ children }) => {
  const session = useSession();
  const { updateAuthAndToken, signOut, auth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (session?.status === 'loading') {
      return;
    }

    if (auth?.session && session?.status === 'authenticated') {
      return;
    }

    if (session?.status === 'authenticated') {
      const fetchStatus = async () => {
        const auth = session.data.user as Auth;
        try {
          updateAuthAndToken(auth);
          await getUserStatus();
          const deeplink = localStorage.getItem('deeplink');
          if (deeplink) {
            navigate(deeplink);
            localStorage.removeItem('deeplink');
            document.cookie = "deeplink=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            return;
          }
        } catch (err) {
          if (auth?.rails) {
            window.location.href = '/en/logout';
          } else {
            navigate('/en/logout/sign-out');
          }
        }
      };
      fetchStatus().then();
    }

    if (auth?.session && session?.status === 'unauthenticated') {
      signOut().then();
      return;
    }
  }, [auth?.session, session, updateAuthAndToken, navigate, signOut]);

  return (
    <NextAuthContext.Provider value={null}>{children}</NextAuthContext.Provider>
  );
};
